import styled from "styled-components";
import { Malachite, WinterSky} from "../colors";

export const StyledHeading = styled.h1`
  color: ${(props) => props.color ? props.color : 'white'};
  ${(props) => props.align && `text-align:${props.align};`};
  font-weight: 900;
  position: relative;
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const StyledHeading2 = styled.h2`
  color: ${(props) => props.color ? props.color : 'white'};
  ${(props) => props.align && `text-align:${props.align};`};
  font-weight: 900;
  
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

export const StyledHeading3 = styled.h3`
  color: ${(props) => props.color ? props.color : 'white'};
  ${(props) => props.align && `text-align:${props.align};`};
  font-weight: 900;
  
  @media (max-width: 1112px) {
    text-align: center;
  }
`;

export const StyledText = styled.p`
    color: ${(props) => props.color ? props.color : 'white;'};
    ${(props) => props.align && `text-align:${props.align};`}
    ${(props) => props.size && `font-size:${props.size}px;`}
    ${(props) => props.weight && `font-weight:${props.weight};`}
    ${(props) => props.align && `text-align:${props.align};`}
`;

export const StyledButton = styled.button`
  font-size: 16px;
  color: ${(props) => props.color ? props.color : 'white'};
  background-color: ${(props) => props.background ? props.background : `rgba(0,0,0,0.5)`};
  padding: 1rem 2rem;
  border-radius: 30px;
  font-weight: 600;
  border: 2px solid ${(props) => props.borderColor ? props.borderColor : 'white'};
  cursor: pointer;
  position: relative;
  max-width: 300px;
  transition: .25s ease-in;
  outline: none;

  ${(props) => props.hide ? hide[props.hide] : ''};
  
    &:hover {
      border: 2px solid white;
      transition: .25s ease-in-out;
      background-color: ${(props) => props.background ? props.background : WinterSky(1)};
      box-shadow: 0px 24px 44px -20px rgba(255,20,116,0.98);
      -webkit-box-shadow: 0px 24px 44px -20px rgba(255,20,116,0.98);
      -moz-box-shadow: 0px 24px 44px -20px rgba(255,20,116,0.98);
    }

  @media (max-width: 768px) {
    padding: 0.9rem 1.5rem;
    font-size: 16px;
  }
`;

export const StyledLinkButton = styled.a`
  font-size: 16px;
  text-decoration: none;
  color: ${(props) => props.color ? props.color : 'white'};
  background-color: ${(props) => props.background ? props.background : `rgba(0,0,0,0)`};
  padding: 1rem 2rem;
  border-radius: 30px;
  font-weight: 600;
  border: 2px solid ${(props) => props.borderColor ? props.borderColor : 'white'};
  cursor: pointer;
  position: relative;
  max-width: 150px;
  transition: .25s ease-in;
  outline: none;
  ${(props) => props.hide ? hide[props.hide] : ''};

  &:hover {
    border: 2px solid white;
    transition: .25s ease-in-out;
    background-color: ${(props) => props.background ? props.background : WinterSky(1)};
    box-shadow: 0px 24px 44px -20px rgba(255,20,116,0.98);
    -webkit-box-shadow: 0px 24px 44px -20px rgba(255,20,116,0.98);
    -moz-box-shadow: 0px 24px 44px -20px rgba(255,20,116,0.98);
  }

  @media (max-width: 768px) {
    padding: 0.9rem 1.5rem;
    font-size: 16px;
  }
`;

export const StyledCarouselSlideSelector = styled.button`
  color: ${(props) => props.color ? props.color : 'white'};
  background-color: ${(props) => props.background ? props.background : 'transparent'};
  padding: .6rem 2rem;
  border-radius: 30px;
  font-size: 16px;
  border:2px solid white;
  transition: 0.25s ease-in-out;
  cursor: pointer;
  
  &.active {
    background-color: transparent;
    border: 2px solid ${Malachite};
    color: white;
    padding: 0.5rem 1.2rem;
    border-radius: 20px;
  }

  &:hover {
    color: black;
    background-color: white;
  }
  
`;

export const StyledCircleButton = styled.button`
  color: ${(props) => props.color ? props.color : 'white'};
  background-color: ${(props) => props.background ? props.background : 'transparent'};
  width: 50px;
  height: 50px;
  border-radius: 30px;
  font-size: 16px;
  border:2px solid white;
  transition: 0.25s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  
  &.active {
    background-color: transparent;
    border: 2px solid ${Malachite};
    color: white;
    padding: 0.5rem 1.2rem;
    border-radius: 20px;
  }

  &:hover {
    color: black;
    background-color: white;
  }
  
  @media (max-width: 1280px) {
    border: 1px solid white;
    width: 40px;
    height: 40px;
  }
  
`;

export const StyledMenuLink = styled.a`
    color: white;
    transition: 0.25s ease-in-out;
    text-decoration: none;
    margin: 0 20px;
    font-weight: 600;
    cursor: pointer;
  
    &.active {
      background-color: transparent;
      border: 2px solid ${Malachite};
      color: white;
      padding: 0.5rem 1.2rem;
      border-radius: 20px;
    }
    
    &:hover {
      border-bottom: 2px solid ${Malachite};
    }
  
  @media (max-width: 1920px) {
    margin: 0 10px;
  }
`;

export const StyledBadge = styled.div`
  background-color: rgba(255,255,255,0.05);

  color: ${props => (props.color ? props.color : 'white')};
  border-radius: 6px;
  border: solid 1px ${props => (props.borderColor ? props.borderColor : 'none')};
  font-size:  ${props => (props.fontSize ? props.fontSize : '14px')};
  padding: 5px 15px;
  margin-right: 10px;
  margin-top: 10px;
  
 
  
  @media (max-width: 768px) {
    margin-right: 0.9rem;
    margin-bottom: 0.7rem;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    margin-right: 0.4rem;
    margin-bottom: 0rem;
  }

`;

const hide = {
    md: (styles) => `
        @media only screen and (min-width: 480px) {
            display:none
        }
    `
}
