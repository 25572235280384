import React,{useEffect,useState} from "react";
import styled from "styled-components";
import {StyledHeading} from "./styled";
import {Malachite} from "./colors";

const StyledSection = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(5,0,19,1) 71%, rgba(12,5,37,1) 100%);
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Loading = () => {

    const [fadeOut,setFadeOut] = useState('');

    useEffect(() => {
        document.body.style.overflow = "visible";
        setTimeout(() => setFadeOut('fade-out'),3000)
    });

    return(
        <StyledSection className={fadeOut}>
            <StyledHeading color={Malachite}>Creando tu universo...<br/></StyledHeading>
            <div className="spinner-box">
                <div className="solar-system">
                    <div className="earth-orbit orbit">
                        <div className="planet-loading earth first"></div>
                        <div className="venus-orbit orbit">
                            <div className="planet-loading venus second"></div>
                            <div className="mercury-orbit orbit">
                                <div className="planet-loading mercury third"></div>
                                <div className="sun"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledSection>
    )
}

export default Loading;