import React,{useEffect,useState} from "react";
import "./styles.scss";
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay';
import Loading from "./components/loading";

export const DelayedHome = loadable(() =>
    pMinDelay(import('./components/home'), 500)
)

function App() {

    const [isLoading,setIsLoading] = useState(true);

    useEffect(() => {

        setTimeout(() => {
            setIsLoading(false);
        },5000);
    });

    useEffect(() => {
        if(isLoading) {
            document.body.style.overflow = "hidden";
        }else{
            document.body.style.overflow = "visible";
        }
    },[isLoading]);

    return (
        <>
            {isLoading && <Loading/>}
            <DelayedHome/>
        </>
    );
}

export default App;
